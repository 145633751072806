/* src/components/ProgressBar/ProgressBar.css */
@import url('../../styles/variables.css');
@import url('../../styles/common.css');

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative; /* Ensure relative positioning for child elements */
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.progress {
  height: 20px;
  background-color: #4caf50;
  text-align: center;
  line-height: 20px;
  color: white;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .progress {
    height: 16px; /* Adjust height as needed for mobile */
    font-size: 12px; /* Adjust font size as needed for mobile */
    line-height: 16px; /* Adjust line-height for vertical centering */
  }
}
