@import url('../../styles/variables.css');
@import url('../../styles/common.css');

.formatted-instructions-container {
    margin: 0;
    padding: 10px;
    max-width: none;
    text-align: center;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
}

.white-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
}

.carousel-title {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 20px;
}

.white-container p, 
.white-container ol, 
.white-container ul {
    font-size: 1.1em;
    line-height: 1.6;
    color: var(--text-color);
    text-align: left;
}

.white-container ol, 
.white-container ul {
    padding-left: 20px;
}

.white-container li {
    margin-bottom: 10px;
}

.white-container li strong {
    color: var(--text-color);
}

.scoring-table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    max-width: 600px; /* Limit the maximum width of the table */
    margin: 20px auto; /* Center the table and add vertical margins */
}

.scoring-table th, .scoring-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.scoring-table th {
    background-color: var(--primary-color);
    color: #fff;
    font-weight: bold;
}

.centered-text {
    text-align: center;
    margin-top: 20px;
}

/* Mobile Styles */
@media (max-width: 600px) {
    .formatted-instructions-container {
        padding: 20px; /* Increase padding for mobile */
    }

    .white-container p, 
    .white-container ol, 
    .white-container ul {
        font-size: 1em;
        line-height: 1.4;
    }

    .scoring-table th, .scoring-table td {
        padding: 6px;
    }
}
