:root {
    --primary-color: #007bff;
    --secondary-color: #4caf50;
    --danger-color: #ff4d4d;
    --background-color: #f0f2f5;
    --text-color: #333;
    --padding: 10px;
    --border-radius: 8px;
    --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    --font-size: 16px;
  }
  