@import url('../../styles/variables.css');
@import url('../../styles/common.css');

.predictions-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.predictions-table th, .predictions-table td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: left;
}

.predictions-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    cursor: pointer;
}

.even-row {
    background-color: #f9f9f9;
}

.odd-row {
    background-color: #ffffff;
}

.prediction-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}

.team-score-input {
    width: 50px;
    padding: 4px;
    text-align: center;
}

.vs-text {
    margin: 0 4px;
}

.submit-button {
    padding: 4px 8px;
    background-color: #ffcc80;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #ffa726;
}

.submit-button.predicted {
    background-color: var(--secondary-color);
    color: white;
}

.copa-predictions-container {
    text-align: center;
}

.slide-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.tournament-logo {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 10px;
}

.tournament-blurb {
    text-align: center;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .predictions-table th, .predictions-table td {
        padding: 8px;
    }

    .team-score-input {
        width: 40px;
    }

    .prediction-input {
        flex-direction: column;
        align-items: flex-start;
    }

    .submit-button {
        align-self: stretch;
    }

    .vs-text {
        display: none;
    }

    .expandable-content {
        display: none;
    }

    .expandable-content.visible {
        display: table-cell;
    }
}

@media (max-width: 480px) {
    .predictions-table th, .predictions-table td {
        font-size: 14px;
    }

    .team-score-input {
        width: 35px;
    }

    .prediction-input {
        gap: 2px;
    }
}

.past-match {
    background-color: rgba(211, 211, 211, 0.5); /* light gray color with 50% opacity */
    color: rgba(128, 128, 128, 0.9); /* gray text with 70% opacity */
  }
  
  .disabled-prediction {
    background-color: rgba(211, 211, 211, 0.5); /* light gray color with 50% opacity */
    color: rgba(128, 128, 128, 0.7); /* gray text with 70% opacity */
    pointer-events: none; /* disable interaction */
    cursor: not-allowed; /* show not-allowed cursor */
  }
  
  .disabled-prediction input {
    background-color: rgba(211, 211, 211, 0.5); /* light gray color with 50% opacity */
    color: rgba(128, 128, 128, 0.7); /* gray text with 70% opacity */
    pointer-events: none; /* disable interaction */
  }
  
  .disabled-prediction button {
    background-color: rgba(211, 211, 211, 0.5); /* light gray color with 50% opacity */
    color: rgba(128, 128, 128, 0.7); /* gray text with 70% opacity */
    pointer-events: none; /* disable interaction */
    cursor: not-allowed; /* show not-allowed cursor */
  }
  