@import url('../../styles/variables.css');
@import url('../../styles/common.css');

.instructions-container {
    margin: 20px;
    padding: var(--padding);
    border-radius: var(--border-radius);
    background-color: #fff;
    box-shadow: var(--box-shadow);
    max-width: 800px;
    margin: 20px auto;
    text-align: center;
}

.instructions-container h2 {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 20px;
}

.instructions-container p, 
.instructions-container ol, 
.instructions-container ul {
    font-size: 1.1em;
    line-height: 1.6;
    color: var(--text-color);
    text-align: left;
}

.instructions-container ol, 
.instructions-container ul {
    padding-left: 20px;
}

.instructions-container li {
    margin-bottom: 10px;
}

.instructions-container li strong {
    color: var(--text-color);
}

.scoring-table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    max-width: 600px; /* Limit the maximum width of the table */
    margin: 20px auto; /* Center the table and add vertical margins */
}

.scoring-table th, .scoring-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.scoring-table th {
    background-color: var(--primary-color);
    color: #fff;
    font-weight: bold;
}

.start-playing-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: var(--secondary-color);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    text-align: center;
}

.start-playing-button:hover {
    background-color: darken(var(--secondary-color), 10%);
}

/* Styles for carousel mode */
.carousel-mode {
    margin: 0;
    padding: 0;
    max-width: none;
    text-align: center;
}

.carousel-mode .instructions-container {
    max-width: 100%;
    padding: 10px;
    box-shadow: none;
    margin: 0 auto;
}

/* Mobile Styles */
@media (max-width: 600px) {
    .instructions-container {
        padding: 20px; /* Increase padding for mobile */
    }

    .instructions-container p, 
    .instructions-container ol, 
    .instructions-container ul {
        font-size: 1em;
        line-height: 1.4;
    }

    .start-playing-button {
        padding: 8px 16px;
        font-size: 0.9em;
    }

    .scoring-table th, .scoring-table td {
        padding: 6px;
    }
}
