:root {
    --filter-gap: 10px;
    --dropdown-background: #f9fafb;
    --dropdown-border: #d1d5db;
    --dropdown-border-radius: 8px;
    --dropdown-padding: 12px;
    --dropdown-font-size: 14px;
    --dropdown-color: #111827;
    --dropdown-icon-size: 16px;
    --button-background: #E0F2FF;
    --button-border: #90CAF9;
    --button-hover-background: #BBDEFB;
    --button-hover-border: #64B5F6;
  }
  
  .map-container {
    position: relative;
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;
    padding: var(--padding);
    background-color: #fff;
    border-radius: var(--border-radius);
    height: calc(100vh - 120px); /* Adjusted height to accommodate buttons */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .filters-container, .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: var(--filter-gap);
    justify-content: center;
    padding: 5px;
    max-width: 800px;
  }
  
  .filters label {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 1;
    max-width: 100%;
    position: relative;
  }
  
  .filters select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background-color: var(--dropdown-background);
    border: 1px solid var(--dropdown-border);
    border-radius: var(--dropdown-border-radius);
    padding: 8px 40px 8px 12px;
    font-size: var(--dropdown-font-size);
    color: var(--dropdown-color);
    background: url('data:image/svg+xml;charset=US-ASCII,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 292.4 292.4" xml:space="preserve"><g><path style="fill:%23000;" d="M287 69.6c-3.6-3.6-9.2-3.6-12.8 0L146.2 197.6 18.2 69.6c-3.6-3.6-9.2-3.6-12.8 0s-3.6 9.2 0 12.8l134 134c1.6 1.6 4 2.6 6.4 2.6s4.6-1 6.4-2.6l134-134c3.4-3.6 3.4-9.2-0.2-12.8z"/></g></svg>') no-repeat right 10px center;
    background-size: var(--dropdown-icon-size);
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .filters select:focus {
    border-color: var(--secondary-color);
    box-shadow: 0 0 0 3px rgba(0, 128, 0, 0.3);
    outline: none;
  }
  
  .buttons-container button {
    color: #fff;
    background-color: var(--secondary-color);
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    margin: 0 5px;
    transition: background-color 0.3s;
  }
  
  .buttons-container button:hover {
    background-color: rgba(0, 128, 0, 0.7);
  }
  
  .country-info-container {
    text-align: center;
    margin-bottom: 10px;
    padding: var(--padding);
    background-color: #fff;
    border-radius: var(--border-radius);
    min-height: 60px;
    height: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .zoom-buttons-container {
    display: none; /* Hidden by default */
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .zoom-button {
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    background-color: var(--button-background);
    border: 1px solid var(--button-border);
    border-radius: 8px;
    transition: background-color 0.3s, border-color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .zoom-button:hover {
    background-color: var(--button-hover-background);
    border-color: var(--button-hover-border);
  }
  
  .map {
    width: 100%;
    flex: 1;
    overflow: hidden;
    height: 100%;
    position: relative;
  }
  
  .country {
    stroke: #888;
    stroke-width: 0.1;
    fill-opacity: 0.8;
    outline: none;
    transition: stroke 0.15s ease, stroke-width 0.15s ease, fill-opacity 0.15s ease;
  }
  
  .country.emphasized {
    stroke: #000;
    stroke-width: 1;
    fill-opacity: 1;
  }
  
  .country.deemphasized {
    stroke: #ddd;
    stroke-width: 0.2;
    fill-opacity: 0.2;
  }
  
  @media (max-width: 1200px) {
    .map-container {
      height: calc(100vh - 120px); /* Adjusted to fit within the viewport */
    }
  }
  
  @media (max-width: 768px) {
    .map-container {
      height: calc(100vh - 120px); /* Adjusted to fit within the viewport */
    }
    .zoom-buttons-container {
        display: flex; /* Change display to flex */
        gap: 5px;
        margin-bottom: 10px;
        justify-content: center; /* Center the buttons horizontally */
        align-items: center; /* Center the buttons vertically */
    }
    
  }
  
  @media (max-width: 480px) {
    .map-container {
      height: calc(100vh - 120px); /* Adjusted to fit within the viewport */
    }
  }
  