@import url('../../styles/variables.css');
@import url('../../styles/common.css');

/* Define common sizes in CSS variables */
:root {
  --input-padding: 0.5rem;
  --button-padding: 0.75rem;
  --border-radius: 4px;
  --gap: 10px;
  --max-width: 300px;
  --primary-color: #007bff;
  --hover-color: rgba(0, 123, 255, 0.7);
  --background-color: rgba(0, 123, 255, 0.2);
  --hover-background-color: rgba(0, 123, 255, 0.4);
  --font-size: 16px; /* Prevent zooming on mobile devices */
}

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.auth-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.auth-form {
  background: white;
  padding: var(--padding);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: 100%;
  max-width: var(--max-width);
  text-align: center;
  box-sizing: border-box;
}

.auth-form h2 {
  margin-bottom: 1.5rem;
}

.auth-form input {
  width: calc(100% - 20px);
  padding: var(--input-padding);
  margin: var(--gap) 0;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  font-size: var(--font-size); /* Ensure consistent font size */
}

.auth-form button {
  width: 100%;
  padding: var(--button-padding);
  background: var(--primary-color);
  border: none;
  border-radius: var(--border-radius);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
  box-sizing: border-box;
}

.auth-form button:hover {
  background: var(--hover-color);
}

.auth-links {
  margin-top: var(--gap);
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
  width: 100%;
}

.auth-links button {
  flex: 1; /* Ensure buttons take equal space */
  background: var(--background-color);
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  padding: var(--button-padding);
  font-size: 1rem;
  border-radius: var(--border-radius);
  transition: background 0.3s;
  box-sizing: border-box;
}

.auth-links button:hover {
  background: var(--hover-background-color);
}

.auth-error {
  color: red;
  margin-bottom: var(--gap);
}

.back-button {
  margin-top: var(--gap);
  background: var(--background-color);
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: var(--border-radius);
  transition: background 0.3s;
  box-sizing: border-box;
}

.back-button:hover {
  background: var(--hover-background-color);
}

.logout-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--background-color);
  border: none;
  color: white;
  border-radius: var(--border-radius);
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;
  font-size: 1rem;
}

.logout-button:hover {
  background-color: var(--hover-background-color);
}

@media (max-width: 600px) {
  .auth-form {
    max-width: 90%;
  }
  .auth-links {
    flex-direction: column;
    gap: var(--gap);
  }
  .auth-links button {
    width: 100%;
  }
  .logout-button {
    padding: 8px;
    font-size: 0.875rem;
  }
}

.success-message {
  color: green;
  margin-top: 10px;
  font-size: 1rem;
}

.auth-error {
  color: red;
  font-size: 1.2em;
  margin-top: 10px;
}
