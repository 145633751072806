body {
    font-family: Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
  }
  
  .container {
    padding: var(--padding);
    margin: auto;
    max-width: 1200px;
  }
  
  .button {
    padding: 10px 20px;
    font-size: var(--font-size);
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: rgba(0, 123, 255, 0.7); /* Adjusted opacity for hover effect */
  }
  