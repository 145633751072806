/* Variables */
:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --table-border: #ddd;
  --table-header-bg: #f2f2f2;
  --table-row-even-bg: #f9f9f9;
  --table-row-odd-bg: #ffffff;
  --table-hover-bg: #e9ecef;
  --container-padding: 10pt;
}

/* General table styles */
.group-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1rem;
}

.group-table th,
.group-table td {
  border: 1px solid var(--table-border);
  padding: 8px;
  text-align: left;
}

.group-table th {
  background-color: var(--table-header-bg);
  font-weight: bold;
  cursor: pointer;
}

.group-table tr:hover {
  background-color: var(--table-hover-bg);
}

.even-row {
  background-color: var(--table-row-even-bg);
}

.odd-row {
  background-color: var(--table-row-odd-bg);
}

/* Responsive styles */
@media (max-width: 768px) {
  .group-table th,
  .group-table td {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .group-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .group-table th,
  .group-table td {
    font-size: 0.875rem;
  }
}

/* Tabs styles */
.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px var(--container-padding);
}

.tab {
  padding: 10px 20px;
  margin: 5px;
  background-color: var(--light-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tab.active {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.tab:hover {
  background-color: var(--primary-color);
  color: var(--light-color);
}

/* Carousel buttons */
.carousel-buttons {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2 * var(--container-padding));
  max-width: 200px;
  margin: 20px var(--container-padding);
}

.carousel-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.carousel-button:hover {
  background-color: darken(var(--primary-color), 10%);
  transform: translateY(-2px);
}

.carousel-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Country flags */
.flag {
  display: inline-block;
  margin: 0 5px;
}

.flag img {
  width: 20px;
  height: auto;
}

/* General container padding */
.container {
  padding: 0 var(--container-padding);
}

/* Leaderboard styles */
.leaderboard-container {
    padding: var(--container-padding);
    margin: auto;
    max-width: 1200px;
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.leaderboard h2 {
    text-align: center;
    font-size: 1.5em; /* Adjusted font size for consistency */
    margin-bottom: var(--container-padding);
}

.leaderboard {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: var(--container-padding);
    font-size: 1rem; /* Ensure consistency with group table font size */
}

.leaderboard th, .leaderboard td {
    border: 1px solid var(--table-border);
    padding: 8px;
    text-align: left; /* Align text to the left for consistency */
    position: relative; /* Added to allow for tooltips */
}

.leaderboard th {
    background-color: var(--table-header-bg);
    font-weight: bold;
}

.leaderboard tr:nth-child(even) {
    background-color: var(--table-row-even-bg);
}

.leaderboard tr:nth-child(odd) {
    background-color: var(--table-row-odd-bg);
}

.leaderboard tr:hover {
    background-color: var(--table-hover-bg);
}

/* Tooltip styles */
.tooltip-text {
    visibility: hidden;
    width: 100%; /* Ensure tooltip matches column width */
    background-color: var(--dark-color);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%; /* Position the tooltip above the text */
    left: 0; /* Align with column */
    opacity: 0;
    transition: opacity 0.3s;
}

.leaderboard th:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    transition-delay: 1.5s; /* Delay before tooltip appears */
}

/* Mobile Styles */
@media (max-width: 600px) {
    .leaderboard-container {
        padding: var(--container-padding);
    }

    .leaderboard h2 {
        font-size: 1.2em;
    }

    .leaderboard th, .leaderboard td {
        padding: 6px;
        font-size: 0.875rem;
    }
    
    /* Show only Rank, Username, and Total Points columns */
    .leaderboard th:nth-child(1),
    .leaderboard td:nth-child(1),
    .leaderboard th:nth-child(2),
    .leaderboard td:nth-child(2),
    .leaderboard th:nth-child(3),
    .leaderboard td:nth-child(3) {
        display: table-cell; /* Ensure these columns are displayed */
    }

    .leaderboard th:nth-child(n+4),
    .leaderboard td:nth-child(n+4) {
        display: none; /* Hide other columns */
    }
}
