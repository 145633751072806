/* Common styles */
.predictions-container {
  padding: 2vw;
}

.match-container {
  border: 1px solid #ddd;
  border-radius: 0.5vw;
  margin-bottom: 2vw;
  padding: 1vw;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.match-info {
  margin-bottom: 1vw;
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 1.2vw;
  padding: 0.5vw 1vw;
}

.match-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.team-container,
.score-country-container,
.submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.country-name {
  font-size: 2vw;
  font-weight: 500;
  margin-bottom: 0.5vw;
  margin-top: 0.5vw;  /* Added margin-top for buffer */
  margin-right: 2vw;
  margin-left: 2vw;
}

.flag-container {
  position: relative;
  width: 15vw;  /* Increased size for desktop */
  max-width: 100px;  /* Increased size */
  min-width: 100px;  /* Increased size */
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 1vw 0;
}

.flag {
  width: 100%;
  height: auto;
}

.score-number {
  margin: 0.5vw 0;
  font-size: 2.5vw;
  font-weight: bold;
}

.score-btn {
  padding: 0.5vw;
  font-size: 2.5vw;
  font-weight: 300;
  margin: 0.5vw 0;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 0.1vw solid #ccc;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-btn:hover {
  background-color: #e0e0e0;
}

.submit-btn {
  padding: 1vw 2vw;
  font-size: 1.5vw;
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;
  margin: 1vw;
  white-space: nowrap;
}

.submit-btn-predicted {
  color: #fff;
  background-color: #28a745;
}

.submit-btn-not-predicted {
  color: #fff;
  background-color: #ff9800;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.submit-btn-predicted:focus,
.submit-btn-predicted:hover {
  background-color: #28a745;
}

.submit-btn-not-predicted:focus,
.submit-btn-not-predicted:hover {
  background-color: #ff9800;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .match-container {
    padding: 2vw;
    width: 100%;
    margin-bottom: 3vw;
  }

  .match-info {
    font-size: 3vw;
    margin-bottom: 1.5vw;
  }

  .flag-container {
    width: 18vw;
    max-width: 100px;
    min-width: 100px;
  }

  .country-name {
    font-size: 3vw;
    margin-top: 1vw;  /* Added margin-top for buffer */
  }

  .score-number {
    font-size: 4vw;
    margin: 1vw 0;
  }

  .score-btn {
    padding: 1vw;
    font-size: 3.5vw;
    width: 5vw;
    height: 5vw;
    margin: 0.5vw 0;
  }

  .submit-btn {
    font-size: 2.5vw;
    padding: 1.5vw 3vw;
    margin-bottom: 2vw;
  }

  /* Adjust the submit button container to be centered */
  .submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

/* Desktop Styles */
@media (min-width: 601px) {
  .match-container {
    padding: 0.5vw;
    height: auto;
    width: 100%;
    margin-bottom: 1vw;
  }

  .match-info {
    font-size: 1vw;
    margin-bottom: 0.5vw;
  }

  .flag-container {
    width: 10vw;  /* Ensure the size is large enough for desktop */
    max-width: 100px;  /* Ensure the size is large enough for desktop */
    min-width: 100px;  /* Ensure the size is large enough for desktop */
  }

  .country-name {
    font-size: 1.5vw;
    margin-bottom: 0.3vw;
    margin-top: 0.5vw;  /* Added margin-top for buffer */
  }

  .score-number {
    font-size: 2vw;
    margin: 0.3vw 0;
  }

  .score-btn {
    padding: 0.3vw;
    font-size: 2vw;
    width: 2.5vw;
    height: 2.5vw;
    margin: 0.3vw 0;
  }

  .submit-btn {
    font-size: 1.2vw;
    padding: 0.8vw 1.5vw;
    margin: 0.8vw;
  }

  /* Adjust the submit button container to be centered */
  .submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
