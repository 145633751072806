@import url('../../styles/variables.css');
@import url('../../styles/common.css');

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.carousel-content {
  text-align: center;
  margin-bottom: 10px;
}

.carousel-title {
  font-size: 1.5em;
  margin-bottom: 5px; /* Reduced margin-bottom */
}

.carousel-nav-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px; /* Increased space below the nav container */
}

.carousel-button-prev,
.carousel-button-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: var(--box-shadow);
  margin: 0 10px;
}

.carousel-button:hover {
  background-color: darken(var(--primary-color), 10%);
  transform: translateY(-2px);
}

.carousel-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.carousel-index {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.carousel-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-dot.active {
  background-color: var(--primary-color);
}

.carousel-dot:hover {
  background-color: rgba(0, 123, 255, 0.7); /* Slightly less transparent on hover */
}
